import clsx from "clsx";
import { motion } from "framer-motion";
import ChevronDown from "icons/ChevronDown";
import Comment from "icons/Comment";
import React, { useEffect, useRef, useState } from "react";
import { angularize } from "react-in-angularjs";
import { useSessionStorage } from "usehooks-ts";

const DEV_CHATBOT_URL =
  "https://devumgcbot.azurewebsites.net/AzureBotChat.html";
const PROD_CHATBOT_URL = "https://umucbot.azurewebsites.net/AzureBotChat.html";

const Chatbot = () => {
  const [showBubble, setShowBubble] = useSessionStorage(
    "chatbot-show-bubble",
    false
  );
  const [showTextBox, setShowTextBox] = useSessionStorage(
    "chatbot-show-textbox",
    true
  );
  const [showChatbot, setShowChatbot] = useState(false);
  const [chatbotAnimate, setChatbotAnimate] = useState({
    scale: 0,
    originX: "325px",
    originY: "540px",
    transition: {
      duration: 0.15,
    },
  });

  // lazy load iframe
  const [loadIframe, setLoadIframe] = useState(false);

  const bubbleRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // show bubble 8 seconds after user logs in
    if (!showBubble) {
      setTimeout(() => {
        setShowBubble(true);
      }, 8000);
    }
  }, []);

  const handleLoadIframe = () => {
    if (loadIframe) return;
    setLoadIframe(true);
  };

  const handleShowChatbotToggle = (show: boolean) => {
    handleLoadIframe();
    setChatbotAnimate((prev) => ({
      ...prev,
      scale: show ? 1 : 0,
    }));
    if (show) {
      setShowChatbot(show);
    } else {
      setTimeout(() => {
        setShowChatbot(show);
        if (bubbleRef.current) {
          bubbleRef.current.focus();
        }
      }, 150);
    }
  };

  return (
    <motion.div
      animate={{ opacity: showBubble ? 1 : 0 }}
      className={clsx("overflow-hidden", !showBubble && "hidden")}
    >
      <div
        className={clsx(
          "fixed bottom-[45px] right-[89px] z-[100] hidden bg-du-yellow px-4 py-3 sm:block",
          showTextBox ? "" : "sm:hidden",
          "after:border-r-agent-main after:pointer-events-none after:absolute after:-right-[12px] after:top-[48px] after:h-0 after:w-0 after:-translate-x-0 after:-translate-y-1/2 after:border-8 after:border-b-du-yellow after:border-l-du-yellow after:border-r-transparent after:border-t-transparent"
        )}
      >
        <div className="relative">
          <button
            onClick={() => {
              setShowTextBox(false);
              if (bubbleRef.current) {
                bubbleRef.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setShowTextBox(false);
                if (bubbleRef.current) {
                  bubbleRef.current.focus();
                }
              }
            }}
            className="absolute -right-[5px] -top-[8px] cursor-pointer border-none text-xl font-bold"
            aria-label="Close"
          >
            &times;
          </button>
          <p className="text-sm font-bold">Hello!</p>
          <p className="text-sm">How can I help you today?</p>
        </div>
      </div>

      <motion.div
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleShowChatbotToggle(false);
          }
        }}
        animate={chatbotAnimate}
        role="dialog"
        aria-labelledby="chat-with-us-title"
        className={clsx(
          "fixed bottom-[125px] right-[20px] z-[100] h-[492px] max-h-[55vh] w-[325px] max-w-[80vw] select-none bg-white shadow-2xl outline-none",
          showChatbot ? "inline-block" : "hidden"
        )}
      >
        <div className="flex items-center justify-between bg-du-yellow px-3 py-2">
          <h2 id="chat-with-us-title" className="text-xs font-bold uppercase">
            chat with us
          </h2>
          <button
            className="cursor-pointer border-none text-2xl font-bold"
            aria-label="Close Chatbot"
            onClick={() => {
              handleShowChatbotToggle(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                handleShowChatbotToggle(false);
              }
            }}
          >
            &times;
          </button>
        </div>
        {loadIframe && (
          <div className="h-[492px] max-h-[55vh] max-w-[80vw] bg-white">
            <iframe
              id="chatbot-iframe"
              src={
                window.location.host === "students.umgc.edu"
                  ? PROD_CHATBOT_URL
                  : DEV_CHATBOT_URL
              }
              allow="microphone;"
              className="h-full w-full border-0"
              title="Chat with us"
            />
          </div>
        )}
      </motion.div>

      <motion.button
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.2,
          },
        }}
        onClick={() => {
          handleShowChatbotToggle(!showChatbot);
          if (showTextBox) {
            setShowTextBox(false);
          }
        }}
        className="fixed bottom-[20px] right-[20px] z-[100] inline-block h-[50px] w-[50px] cursor-pointer rounded-full border-none bg-du-yellow shadow-md outline-none"
        aria-label="Open Chatbot"
        ref={bubbleRef}
      >
        <div className="flex h-[50px] items-center justify-center">
          <motion.div
            animate={{
              opacity: !showChatbot ? 0 : 1,
            }}
            className={clsx(!showChatbot && "hidden")}
          >
            <ChevronDown className="w-6" />
          </motion.div>
          <motion.div
            animate={{
              opacity: showChatbot ? 0 : 1,
            }}
            className={clsx(showChatbot && "hidden")}
          >
            <Comment className="w-7" />
          </motion.div>
        </div>
      </motion.button>
    </motion.div>
  );
};

angularize(Chatbot, "chatbotReact", angular.module("duApp"), {});

export default Chatbot;

import React from "react";
import { angularize } from "react-in-angularjs";

const MilitaryEducationBenefits = () => {
  return (
    <section
      aria-labelledby=""
      id=""
      className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative  mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        width: "95%",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            Military and Education Benefits
          </h3>
        </div>
      </div>

      <br />
      <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
        Tuition Assistance is a military program offering financial support for
        education to eligible members of the Army, Navy, Marines, Air Force, and
        Coast Guard. Each branch has its own criteria for eligibility, service
        obligations, application procedures, and restrictions.
      </p>
      <br />
      <div className="flex-justify-between">
        <div className="text-left">
          <a
            id="militaryEducationBenefits"
            target="blank"
            href="https://www.umgc.edu/military-and-veterans/tuition-benefits/military-tuition-assistance"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Tuition Assistance for Military Personnel
          </a>
        </div>
      </div>
    </section>
  );
};

angularize(
  MilitaryEducationBenefits,
  "militaryEducationBenefitsReact",
  angular.module("duApp"),
  {}
);
export default MilitaryEducationBenefits;
